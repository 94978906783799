/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useRef } from 'react'
import { useMount, useUpdateEffect } from 'ahooks'
import * as Sentry from '@sentry/nextjs'
import type { PostHog } from 'posthog-js'
import { useRouter } from 'next/router'
import { isSSR } from '@/const'

export interface Tracker {

  login(user: { id: number, email: string, isTeacherPreview?: boolean }, school: { id: number, name: string}): void

  pageview(): void

  logout(): Promise<unknown>
}

const IsPosthogEnabled: boolean = !isSSR && process.env.NEXT_PUBLIC_POSTHOG === 'true'

export const TrackerReactContext = createContext<Tracker>({} as Tracker)

let queue: Array<(p: PostHog) => unknown> = []

export const TrackingContext: React.FC = ({ children }) => {

  const posthogRef = useRef<PostHog>()

  const withPosthog = (thunk: (p: PostHog) => unknown) => {
    if (posthogRef.current) {
      try {
        thunk(posthogRef.current)
      } catch (e) {
        Sentry.captureException(e)
      }
    } else if (IsPosthogEnabled) {
      queue.push(thunk)
    }
  }

  const login: Tracker['login'] = async (user, school) => {
    try {
      Sentry.setUser({
        id: user.id.toString(),
        email: user.email
      })
      Sentry.setContext('School', {
        id: school.id,
        name: school.name
      })
      withPosthog(p => {
        const id = p.get_distinct_id()
        if (id.match(/^\d+$/) && id !== user.id.toString()) {
          p.reset()
        }
        const sessionProps = {
          schoolId: school.id,
          authenticated: true
        }
        if (user.isTeacherPreview) {
          Object.assign(sessionProps, {
            teacherPreview: true
          })
        }
        p.register(sessionProps)
        p.identify(user.id.toString(), {
          email: user.email,
        })
      })
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const pageview: Tracker['pageview'] = () => {
    withPosthog(p => p.capture_pageview())
  }

  const logout: Tracker['logout'] = async () => {
    Sentry.setUser(null)
    withPosthog(p => p.reset())
  }

  const tracker = {
    login,
    pageview,
    logout
  }

  useMount(() => {
    if (IsPosthogEnabled) {
      import('posthog-js').then(posthogModule => {
        posthogModule.default.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
          api_host: process.env.NEXT_PUBLIC_POSTHOG_API,
          // @ts-ignore
          disable_session_recording: true,
          loaded: instance => {
            instance.register({ Application: 'student' })
            posthogRef.current = instance
            if (queue.length > 0) {
              const waited = [ ...queue ]
              queue = []
              waited.forEach(withPosthog)
            }
          }
        })
      })
    }
  })

  return <TrackerReactContext.Provider value={tracker}>
    {children}
  </TrackerReactContext.Provider>
}

export function useTracker() {
  return useContext(TrackerReactContext)
}

export const TrackPageView: React.FC = () => {
  const { pathname, query } = useRouter()
  const tracker = useTracker()

  useUpdateEffect(() => {
    tracker.pageview()
  }, [ pathname, query ])

  return null
}

