import { AuthToken } from '@/types'
import browserStorage from '@/utils/browserStorage'

const TOKEN = 'AccessToken'

export const AuthAccessToken = {

  read(): string | null {
    const { value } = browserStorage.get<AuthToken>(TOKEN)
    return value?.access_token ?? null
  },

  write(token: AuthToken): void {
    browserStorage.set(TOKEN, token)
  },

  delete() {
    browserStorage.remove(TOKEN)
  }

}
