import React, { createContext, useContext } from 'react'

export const SSRCurrentURLReactContext = createContext<string>('')

type Props = {
  url: string
}

const SSRCurrentURL: React.FC<Props> = ({children, url}) => {

  return (
    <SSRCurrentURLReactContext.Provider value={url}>
      {children}
    </SSRCurrentURLReactContext.Provider>
  )
}

export default SSRCurrentURL

export const useSSRCurrentURL = () => {
  return useContext(SSRCurrentURLReactContext)
}
