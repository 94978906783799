import React, { createContext, useContext, useEffect } from 'react'
import { Result } from 'antd'
import { CurrentSchoolQuery, MeQuery, useCurrentSchoolQuery, useMeQuery } from '@/graphql/generated'
import { AuthToken } from '@/types'
import { AuthAccessToken } from '@/auth/token'
import { useTracker } from '@/TrackingContext'
import { isSSR } from '@/const'

export type CurrentSchool = CurrentSchoolQuery['school']

export interface AuthContext {
  readonly school: CurrentSchool
  readonly loading: boolean
  readonly user: MeQuery['me'] | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly refetchUser: () => Promise<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly authenticate: (token: AuthToken) => Promise<any>,
}

export const AuthReactContext = createContext<AuthContext>({} as AuthContext)

const AuthContext: React.FC = ({ children }) => {
  const tracker = useTracker()
  const schoolQ = useCurrentSchoolQuery({
    fetchPolicy: 'cache-first'
  })
  const authQ = useMeQuery({
    fetchPolicy: 'cache-first',
    skip: isSSR
  })

  // Нужно для корректной обработки useEffect
  const school = schoolQ.data?.school ?? null
  const user = authQ.data?.me ?? null
  const authQRefetch = authQ.refetch

  useEffect(() => {
    if (user && school) {
      tracker.login({
        id: user.id,
        email: user.email,
        isTeacherPreview: user.isTeacherPreview,
      }, school)
    }
  }, [user?.id ?? null, school?.id ?? null])

  const authenticate = async (token: AuthToken) => {
    AuthAccessToken.write(token)
    const { data: { me } } = await authQRefetch()
    if (me) {
      return me
    } else {
      throw new Error('Необходимо авторизоваться заново')
    }
  }

  if (schoolQ.loading && !school) {
    return null
  }

  if (!school) {
    // const isRoot = ROOT_DOMAINS.includes(window.location.hostname)
    const isRoot = false
    return (
      <Result
        status="404"
        title="Школа не найдена"
        subTitle="По этому адресу никакой школы не найдено. Проверьте адрес."
        extra={
          isRoot ? (
            <a href="/admin/app" className="ant-btn ant-btn-primary">
              Вход для преподавателя
            </a>
          ) : (
            <a href="https://glokurs.ru" className="ant-btn ant-btn-primary">
              На главную
            </a>
          )
        }
      />
    )
  }

  const authContext: AuthContext = {
    user,
    authenticate,
    school,
    loading: isSSR || authQ.loading,
    refetchUser: authQRefetch,
  }

  return (
    <>
      <AuthReactContext.Provider value={authContext}>
        {children}
      </AuthReactContext.Provider>
    </>
  )
}

export default AuthContext

export function useCurrentSchool() {
  return useContext(AuthReactContext).school
}

export function useAuthContext() {
  return useContext(AuthReactContext)
}
