import { isSSR } from '@/const'

const storage = global.window?.localStorage

const isSupported = isSSR ? false : ((localStorage: Storage) => {
  try {
    localStorage.setItem('localStorage', '1')
    localStorage.removeItem('localStorage')
    return true
  } catch (e) {
    console.error('Localstorage is not supported')
    return false
  }
})(storage)

const makeString = (value: any) => {
  switch (typeof value) {
    case 'string':
      return value
    case 'boolean':
    case 'number':
    case 'bigint':
      return `${value}`
    default:
      return JSON.stringify(value)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromString = <T>(value: any): T | null  => {
  if (value == null) {
    return null
  }
  try {
    return JSON.parse(value)
  } catch (e) {
    return value as T
  }
}

let internalStorage =  {} as Record<string, any>

export const browserStorage = {
  set(key: string, value: any) {
    const strValue = makeString(value)
    if (isSupported) storage.setItem(key, strValue)
    internalStorage[key] = strValue
    return { key, value }
  },
  get<T>(key: string): {key: string, value: T | null} {
    if (isSSR) {
      return { key, value: null }
    }
    const value = isSupported
      ? fromString<T>(storage.getItem(key))
      : (internalStorage as Record<string, T>)[key]
    return { key, value }
  },
  remove(key: string) {
    if (isSupported) {
      storage.removeItem(key)
    }
    delete internalStorage[key]
  },
  clear() {
    if (isSupported) {
      storage.clear()
    }
    internalStorage = {}
  },
}

export default browserStorage
